import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      routeInfo: {
        routeId: 0,
        routeName: "",
        isFavorite: true,
        generateStoreInfo: {
          id: 0,
          name: ""
        },
        isChangeStore: true,
        wasteTypeInfo: {
          id: 0,
          name: ""
        },
        manifestTypeInfo: {
          id: 0,
          name: ""
        },
        jwnetItemTypeInfo: {
          isSpecial: true,
          isHarmful: true,
          id: 0,
          name: ""
        },
        cbaItemInfo: {
          id: 0,
          name: ""
        },
        constitutionItemInfo: [
          {
            id: 0,
            name: ""
          }
        ],
        wasteNameInfo: {
          id: 0,
          name: ""
        },
        plansQuantity: 0,
        plansWasteUnitType: {
          id: 0,
          name: ""
        },
        limitQuantity: 0,
        limitWasteUnitType: {
          id: 0,
          name: ""
        },
        quantityConfirmBusinessType: {
          id: 0,
          name: ""
        },
        shapeType: {
          id: 0,
          name: ""
        },
        shapeQuantity: 0,
        disposalWayType: {
          id: 0,
          name: ""
        },
        harmfulInfo: [
          {
            id: 0,
            name: ""
          }
        ],
        disposalUnitPrice: 0,
        disposalPriceWasteUnitType: {
          id: 0,
          name: ""
        },
        valuablesUnitPrice: 0,
        conversionFactorKg: 0,
        conversionFactorM3: 0,
        conversionFactorLiter: 0,
        conversionFactorUnitNumber: 0,
        collectPriceWasteUnitType: {
          id: 0,
          name: ""
        },
        disposalTargetQuantityTypeInfo: {
            id: 0,
            name: ""
        },
        contactNoInfo: [
          {
            id: 0,
            contactNo: ""
          }
        ],
        remarksInfo: [
          {
            id: 0,
            remarks: ""
          }
        ],
        wdsFileName: "",
        routeCollectCompanyInfo: [
          {
            collectCompanyInfo: {
              id: 0,
              name: ""
            },
            collectFactoryInfo: {
              id: 0,
              name: "",
              jwnetId: ""
            },
            loadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            unloadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            destinationInfo: {
              destinationTypeInfo: {
                id: 0,
                name: ""
              },
              id: 0,
              name: "",
              jwnetId: "",
              storeCode: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: 0,
                prefecturesName: "",
                address: "",
                buildingName: ""
              }
            },
            transshipmentJwnetItem: [
              {
                id: 0,
                name: ""
              }
            ]
          }
        ],
        routeDisposalCompanyInfo: {
          disposalCompanyInfo: {
            id: 0,
            name: ""
          },
          disposalLicenceInfo: [
            {
              licenceId: 0,
              licenceNo: "",
              licenceExpiredDate: "2021-08-11",
              municipalityName: ""
            }
          ],
          destinationInfo: {
            destinationTypeInfo: {
              id: 0,
              name: ""
            },
            id: 0,
            name: "",
            jwnetId: "",
            storeCode: "",
            addressInfo: {
              zipCode: "",
              prefecturesId: 0,
              prefecturesName: "",
              address: "",
              buildingName: ""
            }
          },
          disposalJwnetItem: [
            {
              id: 0,
              name: ""
            }
          ]
        },
        labelInfo: [
          {
            labelId: 0,
            labelHeader: "",
            labelTypeId: 0,
            labelDefaultValue: "",
            labelUnit: ""
          }
        ],
        circularEconomyInfo: {
          materialRecyclingRate: "",
          chemicalRecyclingRate: "",
          heatRecoveryRate: "",
          heatRecoveryEfficiency: "",
          otherRecyclingRate: "",
          finalDisposalRate: "",
          landfillRate: "",
          incinerateRate: "",
          co2EmmisionFactorCollect: "",
          co2EmmisionFactorDisposal: "",
          massWasteQuantity: "",
          selfRecycling: "",
          selfLanfillDisposal: "",
          selfDisposal: "",
          selfHeatRecovery: "",
          selfDisposalResidue: "",
          selfDisposalLoss: "",
          selfDisposalRecycling: "",
          selfDisposalLanfillOceandump: "",
          selfDisposalOutsource: "",
          disposalExOutsource: "",
          recycleOutsource: "",
          thermalOutsourceCertified: "",
          thermalOutsource: "",
          vehicleCode: "",
          fuelType: "",
          maxLoadingCapacity: "",
          smallVehicle: "",
        },
        isAutoApproval: true,
        isAutoIssueAfterCollectionDate: true,
        autoIssueExecDateType: {
          id: 0,
          name: ""
        },
        autoIssueExecTime: "",
        isCollectReportAlert: true,
        isAutoIssue: true,
        isDisposalReportAlert: true,
        isActive: true,
        isNecessityCommonCollectReport: null,
        registMemberName: '',
        generateMemberName: '',
        routeDeliveryConfirmation: {
          collectorRegistType: 0,
          generateDeliveryConfirmMail: "",
          collectDeliveryConfirmMail: "",
          disposalDeliveryConfirmMail: "",
          anyMail1: "",
          anyMail2: "",
          anyMail3: "",
          anyMail4: "",
          anyMail5: "",
        },
      },
      wasteUnitTypes: [],
      quantityConfirmMembers: [],
      shapeTypes: [],
      wasteTypeInfo: {},
      manifestTypeInfo: {},
    };
  },
  methods: {
    routeDetailApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          this.routeInfo = res.data;
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },
  },
};
